import * as React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Button from "../../components/Button/Button"
import Layout from "../../components/Layout/Layout"
import Title from "../../components/Title/Title"
import * as FeedingStyles from './infant-feeding-coaching.module.css'
import TxtImg from "../../components/TxtImg/TxtImg"
import Underline from "../../components/Underline/Underline"
import Text from "../../components/Text/Text"
import Appointments from "../../components/Appointments/Appointments"

function Feeding({ data }) {
    return (
        <Layout>
            <Helmet>
                <title>Infant Feeding Coaching | Brixworth Osteopathic Clinic</title>
            </Helmet>
            <section className={FeedingStyles.container}>
                <Title>
                    <h1>Infant Feeding Coaching</h1>
                    <h2>Supporting mother and baby to find the most confident and comfortable feeding experience that both are looking for.</h2>
                    <Button external={true} to="https://eubookings.nookal.com/bookings/location/GGNJC" type="primaryOutline">Book online today</Button>
                </Title>
            </section>
            <section>
                <TxtImg split="half" imgFirst={false} imgSrc={data.feeding} imgAlt="Mother and baby">
                    <Underline>
                        <h2>About Infant Feeding Coaching</h2>
                    </Underline>
                        <p>The goal of infant feeding coaching is to aid and support the feeding mother and baby to find the feeding experience they are both looking for by confidentially addressing fears, concerns and issues that the mother and/or partner may be experiencing. Through the consultation you will explore infant feeding as a whole, its benefits and how you may include feeding into your lifestyle.</p>
                        <p>No feeding experience is the same, with everyone having differing needs and expectations. You will work together with the Infant Feeding coach, including your partner and others who may support you as part of your and your babies support network.</p>
                </TxtImg>
            </section>
            <section className={FeedingStyles.includes}>
                <Text>
                    <Underline>
                        <h2>What Infant Feeding Coaching Includes</h2>
                    </Underline>
                    <p>Support will include:</p>
                    <ul>
                        <li>Pre and post birth breast feeding/chest feeding support and planning</li>
                        <li>Collostrom collection</li>
                        <li>Initiating breast feeding soon after birth</li>
                        <li>Manual and hand expression including storage and bottle feeding</li>
                        <li>Addressing concerns e.g. fears of “not having enough milk”, unsettled babies and painful feeding</li>
                        <li>Attachment and positioning</li>
                        <li>Maintaining lactation</li>
                        <li>Understand how breast feeding works, babies communication and what your baby already knows</li>
                    </ul>
                    <p>Cranial and / or structural osteopathy can be added into the coaching as required.</p>
                </Text>
            </section>
            <section>
                <Appointments fees="feeding" />
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        feeding: file(relativePath: {eq: "infant-feeding.jpg"}) {
            childImageSharp {
                gatsbyImageData(
                    width: 800
                    placeholder: BLURRED
                )
            }
        }
    }
`

export default Feeding